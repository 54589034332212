@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.inner {
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    height: 100%;
}

.result,
.form {
    padding: 4.8rem 2rem;
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem 4.8rem;
}

.form {
    position: relative;
    background-color: var(--colors-white);

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% + 2rem);
        background-color: var(--colors-white);
        content: '';
    }
}

.eyebrow {
    margin-bottom: 1.2rem;
    text-transform: uppercase;
}

.headline {
    margin-bottom: 2rem;
    text-transform: uppercase;

    p {
        margin: 0;
    }
}

.copy {
    p {
        margin: 0;
    }
}

.image {
    width: 100%;
    max-width: 53rem;
    margin: 0 auto 2rem;
    aspect-ratio: 1 / 1;
    object-fit: cover;
}

@include medium {
    .result,
    .form {
        padding: var(--block-padding);
    }

    .result {
        padding-top: 0;
    }
}

@include large {
    .inner {
        display: flex;
        min-height: calc(100vh - var(--header-height) + 2rem);

        @supports (min-height: 100svh) {
            /* stylelint-disable-next-line unit-allowed-list */
            min-height: calc(100svh - var(--header-height) + 2rem);
        }

        .isModal & {
            min-height: 100vh;

            @supports (min-height: 100svh) {
                /* stylelint-disable-next-line unit-allowed-list */
                min-height: 100svh;
            }
        }
    }

    .result,
    .form {
        width: 50%;
        padding: var(--block-padding);
    }

    .result {
        padding-top: 0;
    }

    .form {
        &::before {
            width: 50vw;
        }
    }

    .image {
        margin-bottom: 4rem;
    }

    @media (max-height: 919px) {
        .image {
            max-width: 48rem;
        }

        .result {
            padding-bottom: 4.8rem;
        }

        .form {
            padding-top: 4.8rem;
            padding-bottom: 4.8rem;
        }
    }
}

@include xLarge {
    @media (max-height: 919px) {
        .result {
            margin-top: -4.8rem;
        }
    }
}
