@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    --base-color: var(--primary-color);
    --hover-color: var(--colors-cream);
    --text-color: var(--colors-cream);
    --text-hover-color: var(--primary-color);
}

.headline {
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;

    p {
        margin: 0;
    }
}

.copy {
    margin-bottom: 4.8rem;

    p {
        margin: 0;
    }
}
