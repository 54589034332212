@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.4rem 2rem;

    &.isModal {
        padding: 4.8rem 2rem;
    }
}

.inner {
    position: relative;
    display: flex;
    height: 52.8rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.interactive {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 55rem;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.4rem;
}

.swiper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.answers {
    position: relative;
    z-index: 0;
    display: flex;
    width: clamp(20rem, 67vw, 25rem);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    aspect-ratio: 250/328;
}

.buttonNo,
.buttonYes {
    position: absolute;
    z-index: 1;
    width: clamp(6.5rem, 17vw, 12rem);
    height: clamp(6.5rem, 17vw, 12rem);
    padding: 0;
    border: none;
    border-radius: 50%;
    background-color: var(--primary-color);
    transition: transform $slow $easeOutCubic;
}

.buttonNo {
    left: 0;
    background-color: var(--colors-cream);

    .isHoverNo & {
        transform: scale(1.3);
    }
}

.buttonYes {
    right: 0;

    .isHoverYes & {
        transform: scale(1.3);
    }
}

.buttonNext {
    visibility: hidden;
}

.iconNo,
.iconYes {
    svg {
        width: 100%;
        height: 100%;
    }
}

@include medium {
    .root {
        padding: 4.8rem 6.4rem;
        aspect-ratio: 340/445;

        &.isModal {
            padding: 6.4rem;
        }
    }

    .buttonNo,
    .buttonYes {
        width: 12rem;
        height: 12rem;

        @media (hover: hover) {
            &:hover {
                transform: scale(1.15);
            }
        }

        .isSwipingComplete & {
            pointer-events: none;
        }
    }

    .buttonNo {
        .isHoverNo & {
            transform: scale(1.25);
        }
    }

    .buttonYes {
        .isHoverYes & {
            transform: scale(1.25);
        }
    }

    .interactive {
        max-width: 100rem;
        margin-top: 2.4rem;
    }

    .answers {
        width: clamp(25rem, 26vw, 30rem);
    }
}

@include large {
    .root {
        padding: 6.4rem;

        &.isModal {
            padding: 8.4rem;
        }
    }

    .inner {
        width: 100%;
        height: 100%;
        max-height: 76.8rem;
        justify-content: space-between;
    }

    .answers {
        width: 100%;
        height: clamp(36rem, 52vh, 44.5rem);
    }
}
