@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    width: 100%;

    &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--header-height);
        background-color: var(--secondary-color);
        content: '';
        translate: 0 -100%;
    }
}

.overflow {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: calc(100vh - var(--header-height) + 2rem);
    min-height: 60rem;
    padding-bottom: 2rem; // clear footer divot
    background-color: var(--secondary-color);
    color: var(--primary-color);

    @supports (height: 100svh) {
        /* stylelint-disable-next-line unit-allowed-list */
        height: calc(100svh - var(--header-height) + 2rem);
    }

    .isModal & {
        height: 100vh;
        padding-bottom: 0;

        @supports (height: 100svh) {
            /* stylelint-disable-next-line unit-allowed-list */
            height: 100svh;
        }
    }
}

.isComplete .overflow {
    height: auto;

    .isModal & {
        height: auto;
    }

    .inner {
        position: absolute;
        height: calc(100vh - var(--header-height) + 2rem);

        @supports (height: 100svh) {
            /* stylelint-disable-next-line unit-allowed-list */
            height: calc(100svh - var(--header-height) + 2rem);
        }

        .isModal & {
            height: 100vh;

            @supports (height: 100svh) {
                /* stylelint-disable-next-line unit-allowed-list */
                height: 100svh;
            }
        }
    }

    .stepResult {
        position: relative;
    }
}

.inner {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
}

.step {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    translate: 20% 0;

    &.isActive {
        animation: fade-in-in-right 1s $easeOutCubic forwards;
        pointer-events: auto;

        .isBack & {
            animation: fade-in-in-left 1s $easeOutCubic forwards;
        }

        .isDebug & {
            animation: none;
            opacity: 1;
            translate: 0 0;
        }
    }

    &.isPrevious {
        animation: fade-out-out-left 0.5s $easeOutCubic forwards;

        .isBack & {
            animation: fade-out-out-right 0.5s $easeOutCubic forwards;
        }
    }
}

.back {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    width: 4.8rem;
    height: 4.8rem;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    margin: 0;
    background-color: transparent;
    color: var(--primary-color);
    transition: opacity $slow $easeOutCubic;

    &:disabled {
        opacity: 0;
    }
}

@include large {
    .overflow {
        min-height: 76rem;
    }

    .back {
        top: 2.4rem;
        left: 2.4rem;
    }
}

@keyframes fade-out-out-left {
    from {
        opacity: 1;
        translate: 0 0;
    }

    to {
        opacity: 0;
        translate: -20% 0;
    }
}

@keyframes fade-in-in-left {
    from {
        opacity: 0;
        translate: -20% 0;
    }

    to {
        opacity: 1;
        translate: 0 0;
    }
}

@keyframes fade-in-in-right {
    from {
        opacity: 0;
        translate: 20% 0;
    }

    to {
        opacity: 1;
        translate: 0 0;
    }
}

@keyframes fade-out-out-right {
    from {
        opacity: 1;
        translate: 0 0;
    }

    to {
        opacity: 0;
        translate: 20% 0;
    }
}
