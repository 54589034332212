@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.4rem 2rem;

    &.isModal {
        padding: 4.8rem 2rem;
    }
}

.inner {
    position: relative;
    display: flex;
    height: 52.8rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.answers {
    position: relative;
    display: flex;
    width: 90%;
    max-width: 50rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 1.2rem;
}

@include medium {
    .answers {
        width: 100%;
        max-width: none;
        flex-direction: row;
        flex-wrap: unset;
        margin: 7rem 0 4.2rem;
    }
}

@include large {
    .root {
        padding: 6.4rem;

        &.isModal {
            padding: 8.4rem;
        }
    }

    .inner {
        width: 100%;
        height: 100%;
        max-height: 76.8rem;
        justify-content: space-between;
    }

    .answers {
        margin: 0;
    }
}
