@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.headline {
    width: 100%;
    max-width: 90rem;
    margin-bottom: 1.2rem;
    text-align: center;
    text-transform: uppercase;

    p {
        margin: 0;
        font-size: clamp(2.6rem, 3.8vw, 3.8rem);
    }
}

.copy {
    text-align: center;

    p {
        margin: 0;
    }
}

@include large {
    .headline {
        margin-bottom: 2rem;

        p {
            font-size: clamp(3.8rem, 8.5vw, 4.8rem);
        }
    }
}
