@import 'styles/breakpoints';

.root {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: var(--root-padding, 2.4rem 2.75rem);
    border-radius: 0.8rem;

    &.error {
        background-color: #ffe0de;
        color: var(--colors-cheeseSharpCheddar);
    }

    &.success {
        background-color: #d9f5da;
        color: var(--colors-cheeseMexican4Cheese);
    }
}

@include large {
    .root {
        --root-padding: 3rem 6.5rem;
    }
}
