@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    width: 50%;
    max-width: 20rem;
    padding: 2%;
    border: none;
    margin: 0;
    aspect-ratio: 4/5;
    background-color: transparent;
    transition: scale $default $easeOutCubic, transform $default $easeOutCubic;

    &:nth-of-type(1) {
        transform: rotate(-3deg) translate(3%, 7%);
    }

    &:nth-of-type(2) {
        transform: rotate(1deg) translate(-3%, 9%);
    }

    &:nth-of-type(3) {
        transform: rotate(3deg) translate(4%, -11%);
    }

    &:nth-of-type(4) {
        transform: rotate(-3deg) translate(-3%, -7%);
    }

    &.isActive {
        z-index: 1;
        scale: 1.066;

        .inner {
            translate: 0 -1rem;
        }
    }
}

.inner {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 2rem 0.8rem;
    border: 0.2rem solid var(--primary-color);
    border-radius: 1.2rem;
    background-color: var(--colors-white);
    color: var(--primary-color);
    transition: translate $default $easeOutCubic;

    .isActive & {
        background-color: var(--primary-color);
        color: var(--colors-white);
    }
}

.paragraph {
    font-size: clamp(1.4rem, 3vw, 1.6rem);
}

@include medium {
    .root {
        width: 22%;
        max-width: 30rem;
        padding: 0.5rem;

        &:nth-of-type(1) {
            transform: rotate(-3deg) translate(0%, 0%);
        }

        &:nth-of-type(2) {
            transform: rotate(1deg) translate(0%, 0%);
        }

        &:nth-of-type(3) {
            transform: rotate(-2deg) translate(0%, -2%);
        }

        &:nth-of-type(4) {
            transform: rotate(3deg) translate(1%, -1%);
        }

        &.isActive {
            .inner {
                translate: 0 -2rem;
            }
        }

        @media (hover: hover) {
            &:hover .inner {
                translate: 0 -2rem;
            }
        }
    }
}

@include large {
    .root {
        aspect-ratio: 286/376;
    }

    .inner {
        padding: 10% 5%;
        border-radius: 2rem;
    }

    .paragraph {
        font-size: clamp(1.6rem, 1.6vw, 2.4rem);
    }
}

@include xLarge {
    .inner {
        padding: 6.4rem 2.6rem;
        border: 0.4rem solid var(--primary-color);
    }
}
