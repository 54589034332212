@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: absolute;
    z-index: var(--z-index);
    width: 100%;
    height: 100%;
    cursor: grab;
    pointer-events: none;
    transition: opacity 0.5s $easeOutCubic;

    &:active {
        cursor: grabbing;
    }

    &:nth-of-type(4n + 1) {
        .inner {
            transform: rotate(-5deg);
        }
    }

    &:nth-of-type(4n + 2) {
        .inner {
            transform: rotate(5deg);
        }
    }

    &:nth-of-type(4n + 3) {
        .inner {
            transform: rotate(-2deg);
        }
    }

    &:nth-of-type(4n + 4) {
        .inner {
            transform: rotate(2deg);
        }
    }

    &.isActive {
        z-index: var(--active-z-index);
        pointer-events: auto;
    }

    &.isNo {
        z-index: var(--complete-z-index);
        opacity: 0;

        .inner {
            translate: -100% 0;
        }
    }

    &.isYes {
        z-index: var(--complete-z-index);
        opacity: 0;

        .inner {
            translate: 100% 0;
        }
    }
}

.inner {
    position: absolute;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 0.3rem solid var(--primary-color);
    border-radius: 1.5rem;
    background-color: var(--colors-white);
    box-shadow: -0.4rem 0.4rem 2rem 0 rgba(69, 27, 0, 10%);
    transition: translate 0.5s $easeOutQuad, transform 0.5s $easeOutCubic;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.copy {
    position: absolute;
    top: 61%;
    width: 100%;
    padding: 0 5%;
    text-align: center;
}

.paragraph {
    font-size: 1.7rem;
}

.eyebrow {
    margin-bottom: 0.8rem;
    font-size: 0.9rem;
    letter-spacing: 0.738px;
    text-transform: uppercase;
}

@include medium {
    .paragraph {
        font-size: clamp(1.7rem, 1.8vw, 2rem);
    }

    .eyebrow {
        font-size: clamp(0.9rem, 1.1vw, 1rem);
    }
}

@include large {
    .inner {
        border: 0.4rem solid var(--primary-color);
    }

    .copy {
        top: 57%;
        padding: clamp(1rem, 5%, 2.4rem);
    }

    .paragraph {
        font-size: clamp(1.8rem, 3vh, 2.4rem);
    }

    .eyebrow {
        margin-bottom: 1.2rem;
        font-size: clamp(0.9rem, 1.5vh, 1.2rem);
        letter-spacing: 1px;
    }
}
