@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.4rem;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    text-align: center;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0.5rem;
        background-color: var(--primary-color);
        content: '';
        transition: translate 5s linear;
        translate: -100% 0;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--primary-color);
        content: '';
        translate: 0 100%;
    }

    &.isModal {
        padding: 4.8rem 2.4rem;

        &::before {
            transition: translate 5s linear 1s;
        }
    }

    &.isMounted {
        animation: slide-up 0.6s $easeOutCubic 5.5s forwards;

        &::before {
            translate: 0 0;
        }

        .copy,
        .headline {
            opacity: 1;
            translate: 0 0;
        }

        .image {
            opacity: 1;
            scale: 1;
        }

        &::after {
            animation: slide-in 0.6s $easeOutCubic 5s forwards;
        }
    }

    &.isMounted.isModal {
        animation: slide-up 0.6s $easeOutCubic 6.5s forwards;

        &::after {
            animation: slide-in 0.6s $easeOutCubic 6s forwards;
        }
    }
}

.inner {
    position: relative;
    display: flex;
    height: 52.8rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.lockup {
    position: relative;
    z-index: 1;
    max-width: 45rem;
}

.copy,
.headline {
    opacity: 0;
    transition: translate 0.6s $easeOutCubic, opacity 0.3s $easeOutCubic;
    translate: 0 2.4rem;

    .isModal & {
        transition-delay: 1s;
    }
}

.headline {
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;

    p {
        margin: 0;
        font-size: clamp(3rem, 4vw, 4.4rem);
    }
}

.copy {
    display: flex;
    justify-content: center;
    transition-delay: 0.3s;

    .isModal & {
        transition-delay: 1.3s;
    }
}

.image {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 120%;
    max-width: 44rem;
    object-fit: cover;
    object-position: center bottom;
    opacity: 0;
    scale: 0.8;
    transition: opacity 0.6s $easeOutCubic 0.6s, scale 5.5s $easeOutCubic 0.6s;
    translate: -50% 0;

    .isModal & {
        transition: opacity 0.6s $easeOutCubic 1.6s,
            scale 5.5s $easeOutCubic 1.6s;
    }
}

@include medium {
    .headline {
        p {
            font-size: clamp(3.4rem, 3.5vw, 8rem);
        }
    }
}

@include large {
    .root {
        padding: 4.8rem 0;

        &.isModal {
            padding: 8.5rem 0;
        }
    }

    .inner {
        width: 100%;
        height: 100%;
        max-height: 96.8rem;
        justify-content: space-between;
    }

    .lockup {
        max-width: none;
    }

    .headline,
    .copy {
        z-index: 1;
        max-width: 100rem;
    }

    .copy {
        p {
            max-width: 64rem;
            text-wrap: pretty;
        }
    }

    .headline {
        p {
            font-size: clamp(4.4rem, 6vw, 8rem);
        }
    }

    .image {
        width: auto;
        max-width: none;
        height: clamp(74rem, 90vh, 120rem);
        margin-bottom: -5rem;

        .isModal & {
            margin-bottom: -11rem;
        }
    }

    @media (min-height: 900px) {
        .image {
            height: clamp(74rem, 100vh, 120rem);

            .isModal & {
                margin-bottom: -10rem;
            }
        }
    }
}

@keyframes slide-up {
    from {
        translate: 0 0;
    }

    to {
        translate: 0 -100%;
    }
}

@keyframes slide-in {
    from {
        translate: 0 100%;
    }

    to {
        translate: 0 0;
    }
}
