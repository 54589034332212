@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    --base-color: var(--primary-color);
    --hover-color: var(--colors-cream);
    --text-color: var(--colors-cream);
    --text-hover-color: var(--primary-color);

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}
