@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.4rem 2rem;

    &.isModal {
        padding: 4.8rem 2rem;
    }
}

.inner {
    position: relative;
    display: flex;
    max-width: 48rem;
    height: 52.8rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slider {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 6rem;
}

.imageLeft,
.imageRight {
    position: absolute;
    width: 12rem;
    translate: 0 -5rem;
}

.imageLeft {
    left: -2.4rem;
}

.imageRight {
    right: -2.4rem;
}

.image {
    object-fit: cover;
}

.answers {
    position: relative;
    display: flex;
    width: 100%;
    height: 11rem;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16rem;
}

.copy {
    position: relative;
    top: 2.4rem;
    width: 100%;
}

.paragraph {
    max-width: 40rem;
    padding: 0 1.6rem;
    font-size: 1.8rem;
    text-wrap: pretty;
}

.answerCopy {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    opacity: 0;
    text-align: center;
    transition: opacity $slow $easeOutCubic;
    user-select: none;

    &.isActive {
        z-index: 1;
        opacity: 1;
        transition: opacity $slow $easeOutCubic;
        user-select: auto;
    }
}

.range {
    position: relative;
    width: 100%;
    height: 2.4rem;
}

.input {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0.001;

    &::-webkit-slider-runnable-track,
    &::-moz-range-track {
        height: 2.4rem;
    }

    &::-webkit-slider-thumb,
    &::-moz-range-thumb {
        width: 3.4rem;
        height: 3.4rem;
    }
}

.rangeTrack {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20rem;
    background: var(--colors-white);
}

.rangeTrackInner {
    position: absolute;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 20rem;
    background: var(--colors-white);
}

.rangeProgress {
    position: absolute;
    top: 0;
    left: 3.3rem;
    width: 100vw;
    height: 100%;
    border-radius: 20rem;
    background: var(--primary-color);
    pointer-events: none;
    translate: -100% 0;
}

.rangeThumb {
    position: absolute;
    top: 50%;
    left: 0;
    width: 3.4rem;
    height: 3.4rem;
    border: 0.4rem solid var(--primary-color);
    border-radius: 50%;
    background: var(--colors-white);
    cursor: grab;
    translate: 0 -50%;

    &:active {
        cursor: grabbing;
    }
}

.rangeDots {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.4rem;
}

.rangeDot {
    position: relative;
    width: 0.6rem;
    height: 0.6rem;
    padding: 0;
    border: none;
    border-radius: 50%;
    margin: 0;
    background-color: var(--primary-color);
    cursor: pointer;
    transition: scale $default $easeOutCubic;

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 3rem;
        height: 2rem;
        content: '';
        translate: -50% -50%;
    }

    @media (hover: hover) {
        &:hover {
            scale: 1.2;
        }
    }

    &.isActive,
    &:focus {
        scale: 1.1;
    }
}

@include medium {
    .inner {
        max-width: none;
    }

    .imageLeft,
    .imageRight {
        position: relative;
        width: clamp(9rem, 32%, 18rem);
        flex-shrink: 0;
        translate: 0 1.4rem;
    }

    .answers {
        margin-top: 11rem;
    }
}

@include large {
    .root {
        padding: 6.4rem;

        &.isModal {
            padding: 8.4rem;
        }
    }

    .inner {
        width: 100%;
        height: 100%;
        max-height: 76.8rem;
        justify-content: space-between;
    }

    .imageLeft,
    .imageRight {
        translate: 0 7.4rem;
    }

    .imageLeft {
        margin-right: 2.4rem;
    }

    .imageRight {
        margin-left: 2.4rem;
    }

    .slider {
        max-width: 113rem;
        transform: translateY(-8rem);
    }

    .answers {
        padding: 0;
        transform: translateY(5rem);
    }

    .copy {
        top: 0;
    }

    .paragraph {
        max-width: none;
        font-size: clamp(2rem, 2.4vw, 3rem);
    }

    .range {
        margin: 0 0 3.4rem;
    }

    .rangeThumb {
        width: 4.2rem;
        height: 4.2rem;
    }

    .rangeDots {
        padding: 0 1.8rem;
    }

    .rangeDot {
        width: 0.8rem;
        height: 0.8rem;

        &::after {
            width: 4rem;
            height: 3rem;
        }
    }
}

@include xLarge {
    .range {
        margin: 0 0 5.4rem;
    }

    .answers {
        transform: translateY(6rem);
    }
}
